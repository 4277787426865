<template>
  <div class="species-count-breakdown">
    <div class="unlikely" :style="sectionStyle(breakdown.unlikely)"></div>
    <div class="uncertain" :style="sectionStyle(breakdown.uncertain)"></div>
    <div class="very-likely" :style="sectionStyle(breakdown.veryLikely)"></div>
    <div class="almost-certain" :style="sectionStyle(breakdown.almostCertain)"></div>
  </div>
</template>

<script>
export default {
  props: {
    breakdown: {
      type: Object,
      required: true
    }
  },

  methods: {
    sectionStyle (count) {
      return {
        '--count': count
      }
    }
  }
}
</script>
