<template>
  <div class="species-count" :title="description">
    <p class="species-count-total">{{ count.toLocaleString() }}</p>
    <p class="species-count-label">{{ label }}</p>

    <SpeciesCountBreakdown v-if="breakdown" :breakdown="breakdown" />
  </div>
</template>

<script>
import SpeciesCountBreakdown from './SpeciesCountBreakdown'

import Tooltip from 'bootstrap/js/dist/tooltip'

export default {
  props: {
    count: {
      type: Number,
      default: 0
    },

    label: {
      type: String,
      default: 'detections'
    },

    averageProbability: {
      type: Number,
      required: false
    },

    breakdown: {
      type: Object,
      required: false
    }
  },

  mounted () {
    this.tooltip = new Tooltip(this.$el, { placement: 'right', html: true })
  },

  destroyed () {
    this.tooltip.dispose()
  },

  computed: {
    description () {
      let result = ''

      if (this.breakdown) {
        result += [
          `Almost certain: ${this.breakdown.almostCertain.toLocaleString()}`,
          `Very likely: ${this.breakdown.veryLikely.toLocaleString()}`,
          `Uncertain: ${this.breakdown.uncertain.toLocaleString()}`,
          `Unlikely: ${this.breakdown.unlikely.toLocaleString()}`
        ].join("<br />")
      }

      if (this.averageProbability) {
        if (this.breakdown) {
          result += '<hr />'
        }

        result += `Average probability: ${this.averageProbabilityPercentage}`
      }

      return result
    },

    averageProbabilityPercentage () {
      const percentage = (this.averageProbability * 100).toFixed()
      return `${percentage}%`
    }
  },

  components: {
    SpeciesCountBreakdown
  }
}
</script>
