<template>
  <div>
    <template v-if="!$apollo.queries.counts.loading && counts.detectionCounts.count">
      <Chart ref="chart" :options="chartOptions" class="h-100" />
    </template>
  </div>
</template>

<script>
import { Chart } from 'highcharts-vue'

import DetectionCountsQuery from 'queries/DetectionCounts.graphql'

import { shortPeriodDescription } from 'lib/period'

const THIRTY_DAYS = 30 * 24 * 60 * 60 * 1000

export default {
  props: {
    species: {
      type: Object,
      required: true
    },

    queryVariables: {
      type: Object
    },

    period: {
      type: Object
    }
  },

  data () {
    return {
      counts: null
    }
  },

  apollo: {
    counts: {
      query: DetectionCountsQuery,

      variables () {
        return {
          ...this.queryVariables,
          speciesId: this.species.id
        }
      }
    }
  },

  computed: {
    columnChart () {
      return (this.period.unit === 'month' && this.period.count >= 1) ||
        (this.period.from && this.period.to && this.period.to - this.period.from >= THIRTY_DAYS)
    },

    chartOptions () {
      return {
        chart: {
          type: this.columnChart ? 'column' : 'areaspline',

          plotBackgroundColor: 'transparent',
          backgroundColor: 'transparent'
        },

        title: {
          text: '',
        },

        xAxis: {
          type: 'datetime',
          lineWidth: 0,
          tickLength: 0,
          crosshair: true,
          labels: {
            enabled: false
          },
          title: {
            text: ''
          }
        },

        yAxis: {
          labels: {
            enabled: false
          },

          title: {
            text: shortPeriodDescription(this.period)
          }
        },

        plotOptions: {
          areaspline: {
            fillOpacity: 0.5
          }
        },

        legend: {
          enabled: false
        },

        credits: {
          enabled: false
        },

        tooltip: {
          enabled: true,
          useHTML: true,
          xDateFormat: this.columnChart ? '%m/%d/%Y' : '%m/%d/%Y %I:%M%p',
          headerFormat: this.columnChart ? '<b>Time:</b> {point.key}<br />' : '<b>Time:</b> {point.key} (UTC)<br />',
          pointFormat: '<b>Count:</b> {point.y}'
        },

        series: [{
          name: this.species.commonName,
          color: this.species.color,
          data: this.countData,
          marker: {
            radius: 2
          },
          pointPadding: 0,
          groupPadding: 0
        }]
      }
    },

    countData () {
      return this.counts.detectionCounts.bins.map(({ key, count }) => {
        return [new Date(key).valueOf(), count]
      })
    }
  },

  components: {
    Chart
  }
}
</script>
