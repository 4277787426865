import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    locale: localStorage.getItem('locale') || 'en',
    automaticGain: localStorage.getItem('automaticGain') != 'false',
    liveDetections: localStorage.getItem('liveDetections') != 'false'
  },

  mutations: {
    setLocale (state, locale) {
      state.locale = locale
      localStorage.setItem('locale', locale)
    },

    setAutomaticGain (state, value) {
      state.automaticGain = value
      localStorage.setItem('automaticGain', value)
    },

    setLiveDetections (state, value) {
      state.liveDetections = value
      localStorage.setItem('liveDetections', value)
    }
  },

  actions: {
    setLocale ({ commit }, locale) {
      commit('setLocale', locale)
    },

    setAutomaticGain ({ commit }, value) {
      commit('setAutomaticGain', value)
    },

    setLiveDetections ({ commit }, value) {
      commit('setLiveDetections', value)
    }
  }
})
